import { AccountStatus, Scope } from "@headbot/library";
import { Redirect, useLocation } from "@reach/router";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";
import { useHasMounted } from "../../../hooks/useHasMounted";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { SessionStorageKey } from "../../../services/Constants/SessionStorageKey";
import { SSRSafeSuspense } from "../SSR/SSRSafeSuspense/SSRSafeSuspense";

interface IProps {
    readonly adminOnly?: boolean;
}

export const WithAuth: React.FC<React.PropsWithChildren<IProps>> = ({ children, adminOnly = false }) => {
    const location = useLocation();
    const href = location.href;
    const { tokenData, isLoading } = useAuth();
    const [redirectUrl, setRedirectUrl] = useSessionStorage(SessionStorageKey.LoginRedirectUrl, null);

    const [t] = useTranslation();
    const hasMounted = useHasMounted();

    React.useEffect(() => {
        const shouldRedirectToLogin = tokenData === null;
        if (shouldRedirectToLogin === false) {
            return;
        }
        if (redirectUrl !== null) {
            return;
        }
        setRedirectUrl(href);
    }, [href, redirectUrl, setRedirectUrl, tokenData]);

    if (hasMounted === false) {
        return null;
    }
    if (isLoading) {
        return <>{t("constants.loading")}</>;
    }
    if (tokenData === null) {
        return (
            <SSRSafeSuspense fallback={<>{t("constants.loading")}</>}>
                {<Redirect from="*" to={HeadbotRoute.Login} noThrow />}
            </SSRSafeSuspense>
        );
    }
    if (tokenData?.status === AccountStatus.NotVerified) {
        return (
            <SSRSafeSuspense fallback={<>{t("constants.loading")}</>}>
                {<Redirect from="*" to={HeadbotRoute.Activate} noThrow />}
            </SSRSafeSuspense>
        );
    }
    const isNotAdmin = tokenData.scopes.indexOf(Scope.Admin) === -1;
    if (adminOnly && isNotAdmin) {
        return <Redirect from="*" to={HeadbotRoute.Home} noThrow />;
    }
    return <React.Fragment>{children}</React.Fragment>;
};
